<template>
  <div>
    <b-card title="Crear Destinatario">
      <validation-observer ref="registerRules">
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="i-identificationType"
              label="Tipo de identificación"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo_de_identificacion"
                rules="required"
              >
                <b-form-select
                  id="i-identificationType"
                  placeholder=""
                  v-model="formCreateData.identificationTypeId"
                  :options="optionsIdentificationType"
                  :state="errors.length > 0 ? false : null"
                  @change="getDepartmentsFromService()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="i-identificationNumber"
              label="Nit o número de identificación"
            >
              <validation-provider
                #default="{ errors }"
                name="Numero_de_identificacion"
                rules="required"
              >
                <b-form-input
                  id="i-identificationNumber"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.identificationNumber"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-firstName" label="Primer nombre">
              <validation-provider
                #default="{ errors }"
                name="Primer_nombre"
                rules="required"
              >
                <b-form-input
                  id="i-firstName"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.firstname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-secondName" label="Segundo nombre">
              <b-form-input
                id="i-secondName"
                type="text"
                placeholder=""
                v-model="formCreateData.secondName"
              />
            </b-form-group>
          </b-col> </b-row
        ><b-row>
          <b-col md="6">
            <b-form-group label-for="i-firstLastname" label="Primer apellido">
              <validation-provider
                #default="{ errors }"
                name="Primer_apellido"
                rules="required"
              >
                <b-form-input
                  id="i-firstLastName"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.firstLastname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-secondLastname" label="Segundo apellido">
              <b-form-input
                id="i-secondLastName"
                type="text"
                placeholder=""
                v-model="formCreateData.secondLastname"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-country" label="País">
              <validation-provider
                #default="{ errors }"
                name="Pais"
                rules="required"
              >
                <b-form-select
                  id="i-country"
                  placeholder=""
                  v-model="formCreateData.countryId"
                  :options="optionsCountry"
                  @change="getDepartmentsFromService()"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="i-department"
              label="Departamento/Estado/Provincia"
            >
              <validation-provider
                #default="{ errors }"
                name="Departamento"
                rules="required"
              >
                <b-form-select
                  id="i-department"
                  placeholder=""
                  v-model="formCreateData.departmentId"
                  :options="optionsDepartment"
                  @change="getCitiesFromService()"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-city" label="Ciudad">
              <validation-provider
                #default="{ errors }"
                name="Ciudad"
                rules="required"
              >
                <b-form-select
                  id="i-city"
                  placeholder=""
                  v-model="formCreateData.cityId"
                  :options="optionsCity"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-neighborhood" label="Barrio/Colonia">
              <validation-provider
                #default="{ errors }"
                name="Barrio"
                rules="required"
              >
                <b-form-input
                  id="i-neighborhood"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.neighborhood"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-address" label="Dirección">
              <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules="required"
              >
                <b-form-input
                  id="i-address"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.address"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-email" label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="i-email"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-phone" label="Teléfono fijo">
              <b-form-input
                id="i-phone"
                type="text"
                placeholder=""
                v-model="formCreateData.phone"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-cellphone" label="Teléfono celular">
              <validation-provider
                #default="{ errors }"
                name="Telefono_celular"
                rules="required"
              >
                <b-form-input
                  id="i-cellphone"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.cellphone"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
              <b-form-checkbox
                value="1"
                class="custom-control-primary"
                v-model="formCreateData.active"
              >
                Activo
              </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button
              variant="primary"
              @click="onFormRegisterButtonClick"
              >Crear</b-button
            >
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
  BButton,
  BTable,
  BPagination,
} from "bootstrap-vue";
export default {
  name: "create-alerts",
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      errors: [],
      optionsCountry: [],
      optionsDepartment: [],
      optionsCity: [],
      optionsIdentificationType: [],
      formCreateData: {
        identificationTypeId: "",
        identificationNumber: "",
        countryId: "",
        departmentId: "",
        cityId: "",
        firstname: "",
        secondName: "",
        firstLastname: "",
        secondLastname: "",
        cellphone: "",
        address: "",
        email: "",
        phone: "",
        postcodeId: "",
        neighborhood: "",
      },
    };
  },
  created() {
    this.getIdentificationsTypeFromService();
    this.getCountriesFromService();
  },
  methods: {
    getIdentificationsTypeFromService() {
      this.$http
        .get(`api/tiposdeidentificacion/${process.env.VUE_APP_APPID}`, {
          headers: {
            Authorization: `Bearer ${
              this.$session.get("userData").access_token
            }`,
          },
        })
        .then((response) => {
          this.optionsIdentificationType = [];
          response.data.datos.forEach((idType) => {
            let itemIdType = {
              value: idType.id,
              text: idType.descripcion,
            };
            this.optionsIdentificationType.push(itemIdType);
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getCountriesFromService() {
      this.$http
        .get(
          `api/casillero/clientes/getCountries/${process.env.VUE_APP_APPID}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          response.data.forEach((country) => {
            let itemCountry = {
              value: country.codigo,
              text: country.nombre,
            };
            this.optionsCountry.push(itemCountry);
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDepartmentsFromService() {
      this.$http
        .get(
          `api/casillero/clientes/getDepartaments/${this.formCreateData.countryId}/${process.env.VUE_APP_APPID}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.optionsDepartment = [];
          response.data.forEach((department) => {
            let itemDepartment = {
              value: department.id,
              text: department.nombre,
            };
            this.optionsDepartment.push(itemDepartment);
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getCitiesFromService() {
      let str = "Bearer " + this.$session.get("userData").access_token;
      this.$http
        .get(
          `api/casillero/clientes/getCities/${this.formCreateData.departmentId}/${process.env.VUE_APP_APPID}`,
          {
            headers: { Authorization: str },
          }
        )
        .then((response) => {
          this.optionsCity = [];
          response.data.forEach((city) => {
            let itemCity = {
              value: city.id,
              text: city.nombre,
            };
            this.optionsCity.push(itemCity);
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    CreateRecipient() {
      let str = "Bearer " + this.$session.get("userData").access_token;
      this.$http
        .post(
          `api/casillero/destinatarios`,
          {
            cliente_id: store.state.clients.infoUser.id,
            primer_nombre: this.formCreateData.firstname,
            segundo_nombre: this.formCreateData.secondName,
            primer_apellido: this.formCreateData.firstLastname,
            segundo_apellido: this.formCreateData.secondLastname,
            pais_codigo: this.formCreateData.countryId,
            departamento_id: this.formCreateData.departmentId,
            ciudad_id: this.formCreateData.cityId,
            telefono_celular: this.formCreateData.cellphone,
            direccion: this.formCreateData.address,
            email: this.formCreateData.email,
            tipo_de_identificacion_id: this.formCreateData.identificationTypeId,
            numero_de_identificacion: this.formCreateData.identificationNumber,
            telefono_fijo: this.formCreateData.phone,
            codigo_postal_id: this.formCreateData.postcodeId,
            barrio: this.formCreateData.neighborhood,
          },
          {
            headers: { Authorization: str },
          }
        )
        .then((response) => {
          this.$swal({
            title: "Crear Destinatario",
            text: "¡Destinatario Creado con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(response);
            this.$router.push("/destinatarios");
          });
        })
        .catch((errors) => {
          console.log(errors);
          this.$swal({
            title: "Crear Destinatario",
            text: "Error al crear destinatario",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    validationFormRegister() {
      return new Promise((resolve, reject) => {
        this.$refs.registerRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFormRegisterButtonClick() {
      this.validationFormRegister().then((validForm) => {
        if (validForm) {
          this.CreateRecipient();
        }
      });
    },
  },
};
</script>